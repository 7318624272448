import Message from "BarkUI/message_component/Message.js";

let flashAmplitude = {
  trackEvent: () => { }
};

if (window.AmplitudeTracking) {
  flashAmplitude = AmplitudeTracking(window);
}

let setFlashCookie = () => { };

if (typeof setCookie !== "undefined") {
  setFlashCookie = setCookie;
}

const Flash = {
  props: {
    variant: {
      type: String,
      required: true
    },
    mountedAmplitudeEvent: {
      type: Object,
      required: false
    },
    onCloseAmplitudeEvent: {
      type: Object,
      required: false
    },
    onCloseCookie: {
      type: Object,
      required: false
    }
  },

  components: {
    Message
  },

  data: function () {
    return {
      amplitudeInstance: flashAmplitude,
      show: true
    };
  },

  computed: {
    closeAfterTimeout() {
      return this.variant === "success";
    }
  },

  methods: {
    setOnCloseCookie() {
      const date = new Date();
      const oneDayInMilleseconds = 1000 * 60 * 60 * 24; // 1000ms/sec * 60 sec/min * 60min/hr * 24hr/day
      const expireOneDayEpoch = date.setTime(
        date.getTime() +
          (this.onCloseCookie.durationInMs || oneDayInMilleseconds)
      );
      const expireOneDayGmt = "; expires=" + date.toGMTString();

      setFlashCookie(this.onCloseCookie.name, expireOneDayEpoch, {
        expires: expireOneDayGmt,
        path: "/"
      });
    },

    onCloseClick() {
      if (this.onCloseAmplitudeEvent) {
        this.amplitudeInstance.trackEvent(
          this.onCloseAmplitudeEvent.eventName,
          this.onCloseAmplitudeEvent.eventProperties
        );
      }

      if (this.onCloseCookie) {
        this.setOnCloseCookie();
      }

      this.show = false;
    }
  },

  mounted() {
    if (this.closeAfterTimeout) {
      setTimeout(() => (this.show = false), 6000);
    }

    if (this.mountedAmplitudeEvent) {
      this.amplitudeInstance.trackEvent(
        this.mountedAmplitudeEvent.eventName,
        this.mountedAmplitudeEvent.eventProperties
      );
    }
  }
};

export default Flash;
