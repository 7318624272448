import Vue from "Instances/BarkVue";
import FocusLock from "vue-focus-lock";

// Bark UI components
import Flash from "BarkUI/flash_component/Flash.js";
import Modal from "BarkUI/modal_component/Modal";

// barkbox-rails components
import Chat from "../components/Chat.vue";

import { escapeKeyPressed, isIphone, isAndroid } from "../utils/general";

// CCPA MODAL
import accountProfileServices from "../services/accountProfileServices";
import chatMixin from "../mixins/chatMixin";

const EventBus = new Vue();

/*
  Keyboard-only focus helper.

  Design spec asks for keyboard-only focus states on certain elements (checkboxes, submit buttons, etc.)

  For now, we're using this simple snippet from:
  https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2

  Note: we are not solving for people who use their mouse & keyboard at this time. If you tab, then use
  your mouse, you will see focus states on click. Design team is fine with this.

  Alternatives:
  - :focus-visible, though support is low
  - focus-visible polyfill, which seems to be the leading solution but this has proven buggy in our testing

  TODO: see what Level Access makes of this.
*/

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    handleSkipLink();
    document.body.classList.add("keyboard-user");
    window.removeEventListener("keydown", handleFirstTab);
  }
}

function handleSkipLink() {
  const skipLink = document.getElementById("skip-navigation");

  if (skipLink) {
    skipLink.addEventListener("focus", () => {
      skipLink.classList.remove("sr-only");
    });

    skipLink.addEventListener("blur", () => {
      skipLink.classList.add("sr-only");
    });
  }
}

window.addEventListener("keydown", handleFirstTab);

/*
  Site header

  NOTE: Depends on window.amplitude / window.AmplitudeTracking.
  These are not imported here because they are already made available globally via app/views/shared/_scripts.html.erb.
*/
new Vue({
  el: document.getElementById("site-header"),
  name: "SiteHeader",

  components: {
    Chat,
    FocusLock
  },

  mixins: [chatMixin],

  data: function () {
    return {
      isMobileNavOpen: false,
      mobileNavHeight: 0
    };
  },

  computed: {
    ariaExpanded: function () {
      return this.isMobileNavOpen.toString();
    }
  },

  mounted() {
    this.$bodyEl = document.getElementsByTagName("body")[0];
    document.addEventListener("keydown", this.onKeyDown);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },

  methods: {
    toggleMobileNav() {
      if (this.isMobileNavOpen) {
        this.mobileNavHeight = 0;
        this.$bodyEl.style.overflow = "";
      } else {
        this.$bodyEl.style.overflow = "hidden";
        this.mobileNavHeight = `${window.innerHeight - 60}px`;
      }

      this.isMobileNavOpen = !this.isMobileNavOpen;

      this.trackClick("Mobile Header Toggle");
    },

    onKeyDown(e) {
      if (escapeKeyPressed(e)) {
        this.toggleMobileNav();
      }
    },

    trackClick(amplitudeName, properties = {}) {
      if (AmplitudeTracking && amplitudeName) {
        AmplitudeTracking(window).trackEvent(amplitudeName, properties, true);
      }
    }
  }
});

// Site footer
new Vue({
  el: document.getElementById("site-footer"),

  name: "SiteFooter",

  computed: {
    isIphone,
    isAndroid
  },

  methods: {
    onCcpaLinkClick() {
      EventBus.$emit("show-ccpa-modal");
    },

    trackClick(properties = {}, amplitudeName = "Footer Link Click") {
      if (AmplitudeTracking && amplitudeName) {
        AmplitudeTracking(window).trackEvent(amplitudeName, properties, true);
      }
    },

    onProductLinkClick(product) {
      this.trackClick({
        Brand: product
      });
    }
  }
});

// CCPA Modal
new Vue({
  el: document.getElementById("ccpa-modal-wrapper"),

  name: "CcpaModal",

  components: {
    Modal
  },

  mixins: [chatMixin],

  data() {
    return {
      showCcpaModal: false,
      ccpaCheckbox: getCookie("CCPA_OPT_OUT") === "true"
    };
  },

  mounted() {
    EventBus.$on("show-ccpa-modal", () => (this.showCcpaModal = true));
  },

  methods: {
    closeCcpaModal() {
      this.showCcpaModal = false;
    },

    onSubmitCcpaForm() {
      setCookie("CCPA_OPT_OUT", this.ccpaCheckbox, {
        path: "/",
        expires: "Tue, 19 Jan 2038 03:14:07 GMT"
      });

      if (window.current_user) {
        accountProfileServices.updateNotifPreferences(window.current_user.id, {
          ccpa_opt_out: this.ccpaCheckbox ? 1 : 0
        });
      }

      this.showCcpaModal = false;
    }
  }
});

new Vue({
  el: document.getElementById("flash-messages"),
  name: "FlashMessages",
  components: {
    Flash
  }
});
