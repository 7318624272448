const Message = {
  props: {
    inlineMessageClickAmplitudeEvent: {
      type: Object,
      required: false
    }
  },

  data: function () {
    return {
      amplitudeInstance: AmplitudeTracking(window)
    };
  },

  methods: {
    handleInlineMessageClick() {
      if (this.inlineMessageClickAmplitudeEvent) {
        this.amplitudeInstance.trackEvent(
          this.inlineMessageClickAmplitudeEvent.eventName,
          this.inlineMessageClickAmplitudeEvent.eventProperties
        );
      }
    }
  }
};

export default Message;
